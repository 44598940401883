.ff-poppins {
    font-family: "Poppins", sans-serif;
}

.ff-shojumaru {
    font-family: "Shojumaru", sans-serif;
}

.ff-roundy-rainbows {
    font-family: "RoundyRainbows", sans-serif;
}

main {
    font-family: "Poppins", sans-serif;
}